<template>
  <ProductActionsAtom>
    <router-link class="open" v-if="canOpen" :to="to">open</router-link>
    <button class="buy" v-else-if="canBuy">buy</button>
    <button class="request-trial" v-if="canRequestTrial" :disabled="true">
      <span>request trial</span>
    </button>
    <button class="upgrade" v-if="canUpgrade">
      <ArrowUpIcon />
      <span>upgrade</span>
    </button>
  </ProductActionsAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ArrowUpIcon } from 'vue-feather-icons'

import { buttonReset, flexCenter } from '@styles/mixins'

const ProductActionsAtom = styled('div')`
  display: flex;
  gap: 2px;
  max-height: 2rem;
  overflow: hidden;
  border-radius: 0.5rem;
  > button,
  > a {
    ${buttonReset}
    ${flexCenter}
    font-size: 14px;
    padding: 0.25rem 0.5rem;
    > .feather {
      width: 16px;
      height: 16px;
      margin-right: 0.25rem;
    }
    &.buy {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.atomic.green};
    }
    &.open {
      color: ${({ theme }) => theme.colors.white} !important;
      background: ${({ theme }) => theme.colors.atomic.primary};
    }
    &.upgrade,
    &.request-trial {
      color: ${({ theme }) => theme.colors.normalFontColor};
      background: ${({ theme }) => theme.colors.atomic.dropdownButton};
    }
    &:first-child {
      min-width: 4rem;
    }
    &:last-child {
      min-width: 6rem;
    }
    &:disabled {
      background: ${({ theme }) => (theme.isDark ? theme.colors.atomic.osloGrey : theme.colors.lightestGrey)};
    }
  }
`

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    ProductActionsAtom,
    ArrowUpIcon,
  },
  computed: {
    to() {
      return {
        path: this.product.entryPoint,
      }
    },
    canBuy() {
      return !this.product.tierLevels.some(s => s.isPurchased)
    },
    canOpen() {
      if (!this.canBuy && this.product.entryPoint) {
        return true
      }
      return false
    },
    highestOrderedTierLevel() {
      return (this.product?.tierLevels ?? []).reduce((highest, tierLevel) => {
        if (tierLevel.myAcquisitions.some(myAcquisition => myAcquisition.status === 'ORDERED')) {
          if ((highest?.tierLevel ?? 0) < tierLevel.tierLevel) {
            return tierLevel
          }
        }
        return highest
      }, null)
    },
    highestPurchasedTierLevel() {
      return (this.product?.tierLevels ?? []).reduce((highest, tierLevel) => {
        if (tierLevel.isPurchased) {
          if ((highest?.tierLevel ?? 0) < tierLevel.tierLevel) {
            return tierLevel
          }
        }
        return highest
      }, null)
    },
    highestTierLevel() {
      return (this.product?.tierLevels ?? []).reduce((highest, tierLevel) => {
        if ((highest?.tierLevel ?? 0) < tierLevel.tierLevel) {
          return tierLevel
        }
        return highest
      }, null)
    },
    canUpgrade() {
      if (this.canBuy) {
        return false
      }
      if (this.product?.tierLevels?.some(e => !e.isPurchased)) {
        return true
      }
      return false
    },
    canRequestTrial() {
      return false
    },
  },
}
</script>
