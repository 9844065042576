var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ProductActionsAtom', [_vm.canOpen ? _c('router-link', {
    staticClass: "open",
    attrs: {
      "to": _vm.to
    }
  }, [_vm._v("open")]) : _vm.canBuy ? _c('button', {
    staticClass: "buy"
  }, [_vm._v("buy")]) : _vm._e(), _vm.canRequestTrial ? _c('button', {
    staticClass: "request-trial",
    attrs: {
      "disabled": true
    }
  }, [_c('span', [_vm._v("request trial")])]) : _vm._e(), _vm.canUpgrade ? _c('button', {
    staticClass: "upgrade"
  }, [_c('ArrowUpIcon'), _c('span', [_vm._v("upgrade")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }