var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ProductIconMolecule', [_vm.upload ? _c('ResponsiveImageAtom', {
    attrs: {
      "upload": _vm.upload
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }