var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isSVG ? _c('InlineSvg', {
    attrs: {
      "src": _vm.src
    }
  }) : _vm.isImage ? _c('ResponsiveImage', {
    attrs: {
      "src": _vm.src,
      "srcset": _vm.srcset,
      "sizes": _vm.sizes
    }
  }) : _c('ErrorAtom', {
    attrs: {
      "error": _vm.error
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }