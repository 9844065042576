<template>
  <ProductIconMolecule>
    <ResponsiveImageAtom v-if="upload" :upload="upload" />
  </ProductIconMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { flexCenter } from '@styles/mixins'

import ResponsiveImageAtom from '../../../../archon/src/components/Atomic/Atoms/ResponsiveImageAtom.vue'

const ProductIconMolecule = styled('div')`
  ${flexCenter}
  width: 4rem;
  height: 4rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => (theme.isDark ? theme.colors.darkestGrey : theme.colors.lightestGrey)};
  background: ${({ theme }) => theme.colors.mainBG};
`

export default {
  inject: ['theme'],
  props: {
    upload: {
      type: Object,
      required: true,
    },
  },
  components: {
    ProductIconMolecule,
    ResponsiveImageAtom,
  },
  computed: {
    // isFeatherIcon() {
    //   return this.iconPath.indexOf('feather-') > -1
    // },
    // isLogo() {
    //   return !this.isFeatherIcon
    // },
    // logoSrc() {
    //   return this.theme.isDark ? this.iconPath.replace('.png', 'Dark.png') : this.iconPath.replace('.png', 'Light.png')
    // },
    // featherIconName() {
    //   if (!this.isFeatherIcon) {
    //     return null
    //   }
    //   const r =
    //     this.iconPath
    //       .replace('feather-', '')
    //       .split('-')
    //       .map(m => m.charAt(0).toUpperCase() + m.slice(1))
    //       .join('') + 'Icon'
    //   return r
    // },
  },
}
</script>
