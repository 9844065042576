<template>
  <ErrorAtomStyled>
    <div>
      <strong>
        {{ $t('messages.errorLoadingWidget') }}
      </strong>
    </div>
    <div v-if="hasAnyTalpaRole">
      {{ errorMessage }}
    </div>
  </ErrorAtomStyled>
</template>

<script>
import { hasAnyTalpaRoleInToken } from '@common/utils/src'
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'

const ErrorAtomStyled = styled('div')`
  ${flexCenter}
  flex-direction: column;
  padding: 1rem;
`

export default {
  props: {
    error: {
      type: Error,
      required: true,
    },
  },
  components: {
    ErrorAtomStyled,
  },
  computed: {
    errorMessage() {
      const err = this.error
      if (err instanceof Error) {
        return err.message
      }
      return 'Unknown Error'
    },
    hasAnyTalpaRole() {
      return hasAnyTalpaRoleInToken(this.$keycloak.token)
    },
  },
}
</script>
