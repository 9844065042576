<template>
  <InlineSvg v-if="isSVG" :src="src" />
  <ResponsiveImage v-else-if="isImage" :src="src" :srcset="srcset" :sizes="sizes" />
  <ErrorAtom v-else :error="error" />
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import InlineSvg from 'vue-inline-svg'
// import SVGInject from '@iconfu/svg-inject'

import ErrorAtom from '../../../../../common/components/src/ErrorAtom.vue'

const ResponsiveImage = styled('img')`
  max-width: 100%;
`

export default {
  props: {
    expectedSizes: {
      type: Array,
      default: () => ['225px'],
    },
    upload: {
      type: Object,
      required: true,
    },
  },
  components: {
    InlineSvg,
    ResponsiveImage,
    ErrorAtom,
  },
  // data() {
  //   return {
  //     SVGInject,
  //   }
  // },
  computed: {
    isSVG() {
      return this.upload?.mimetype === 'image/svg+xml'
    },
    isImage() {
      return this.upload?.type === 'IMAGE'
    },
    src() {
      if (this.upload?.images?.length > 0) {
        const max = this.upload.images.reduce(
          (acc, item) => {
            if (item.resolution > acc?.resolution) {
              return item
            }
            return acc
          },
          { resolution: 0 },
        )
        return max.preSignedURL
      }
      return this.upload?.preSignedURL
    },
    srcset() {
      if (this.upload?.images?.length > 0) {
        const versions = this.upload.images.reduce((acc, image) => {
          acc.push(`${image.preSignedURL} ${image.resolution}w`)
          return acc
        }, [])
        return versions.join(', ')
      }
      return undefined
    },
    sizes() {
      return this.expectedSizes.join(', ')
    },
    error() {
      return new Error(`upload '${this.upload?.id}' is not an image but ${this.upload.type}`)
    },
  },
}
</script>
